import React, {FunctionComponent} from "react";
import {configs, fetch} from "../../api";
import {ConfigResponse, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {handleScroll} from "../../utils/util";
import {MapToFilter} from "../filters/mapping";
import {getOrdering} from "../filters/ordering";
import CustomPageHeader from "./CustomPageHeader";
import {useLocation} from "react-router-dom";
import {LOCATION_ID_VALUE} from "../../utils/constants";
import {Trans} from "../lib/CustomTransComponent";
import {useAtomValue} from "jotai";
import {locationIdAtomVariable} from "../../view/MainView";

interface ReviewCommentsProps {
}

const ReviewComments: FunctionComponent<ReviewCommentsProps> = () => {
    const location: {pathname: string, state: {[key: string]: string}} = useLocation()
    const locationID = useAtomValue(locationIdAtomVariable);

    return <TableV2
        header={<CustomPageHeader
            title={<Trans i18nKey={`review_comments`} defaults={`Review Comments`}/>}
            start_date={location?.state?.start_date}
            end_date={location?.state?.end_date}
            quarter_date={location?.state?.quarter_date}
            quarter_name={location?.state?.quarter_name}
            weeks={location?.state?.weeks}
            remove_back_button={location?.state?.start_date===undefined}
        />}
        schema={'review_comments'}
        fetchConfig={async (schema: string) => {
            const response = await configs(schema) || {}
            response.data.rows = response.data.rows.map((row: any) => {
                if (row.name) {
                    row.title = <Trans i18nKey={row.name} defaults={row.title}/>;
                }
                return row;
            });
            return response as ConfigResponse
        }}
        fetchData={async (request) => {
            handleScroll()
            const filters = MapToFilter(request.options?.values || {})
            const column = request.options.inbuilt?.sort.column || 'id'
            const direction = request.options.inbuilt?.sort.direction || 'asc'
            const orderBy = getOrdering(column, direction);
            const payload = {
                tableName: 'review_dashboard_view',
                orderBy: orderBy,
                filter:[...filters, {name: 'location_id', value: locationID}],
                page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
            }
            let response = await fetch(payload)

            const result = response?.data?.rows?.filter(checkQuarterStatus);
            function checkQuarterStatus(value: any) {
                return value?.dashboard_status !== 'Not_completed';
            }
            response.data.rows = result

            return response as Response
        }}
        scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
    />
}

export default ReviewComments;
