import React, {useEffect} from "react";
import {Avatar, Button, Drawer, Grid, Layout, Menu, Space, Typography} from 'antd'
import {atom, useAtom, useSetAtom} from 'jotai';
import {
    BankOutlined,
    DashboardOutlined,
    DownloadOutlined,
    EnvironmentOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    MonitorOutlined,
    SettingOutlined,
    SnippetsOutlined,
    TeamOutlined,
    ExportOutlined
} from '@ant-design/icons'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import fullImage from '../assets/sbca_logo.png'
import squareImage from '../assets/sbca_square.jpg'
import {Route, useHistory, useLocation} from "react-router-dom";
import {Users} from '../components/Users'
import {Locations} from "../components/Locations";
import Company from "../components/Company";
import Settings from "../components/Settings";
import Downloads from "../components/Downloads"
import CrashReports from "../components/CrashReports";
// import {getUserInfo} from "../api";
import {AuthContext} from "../components/user/AuthProvider"
import ResetPassword from "../components/ResetPassword";
import {Inspections} from "../components/Inspections";
import {DailyHours} from "../components/DailyHours";
import {AddDailyHours} from "../components/AddDailyHours";
import {AddWeeklyHours} from "../components/AddWeeklyHours";
import SummaryReport from "../components/report/Reports";
import {CompanyAcquisition} from "../components/CompanyAcquisition";
import {ReportPDF} from "../components/report/ReportPDF";
import {AuditTrail} from "../components/AuditTrail"
import CertifiedSettings from "../components/CertifiedSettings";
import {Profile} from "../components/user/Profile";
import {Validate} from "../components/lib/Unauthorized";
import {ConfigsContextProvider} from "../components/configs/ConfigsProvider";
import {fetch_company, fetch_locations} from "../api";
import {unstable_batchedUpdates} from "react-dom";
import Dashboard from "../components/dashboard/Dashboard";
import ReviewComments from "../components/dashboard/ReviewComments";
import ReviewOOCComments from "../components/dashboard/ReviewOOCComments";
import SpanBreakdown from "../components/dashboard/SpanBreakdown";
import SendMail from "../components/dashboard/SendMail";
import {InspectionExport} from "../components/InspectionExport";
import {Trans} from "../components/lib/CustomTransComponent";
import {ReviewApprovals} from "../components/dashboard/ReviewApprovals";
import {ReviewRequests} from "../components/dashboard/ReviewRequests";

export interface ViewPortContextProps {
    isMobileView?: boolean
}

export interface ResponsiveMenuContextProps {
    collapsed?: boolean
    setCollapsed: (data: boolean) => void
}

export const ViewPortContext = React.createContext<ViewPortContextProps>({isMobileView: false})
export const ResponsiveMenuContext = React.createContext<ResponsiveMenuContextProps | undefined>(undefined)

export const ResponsiveMenu: React.FunctionComponent = (props) => {

    return <ViewPortContext.Consumer>
        {
            view => <ResponsiveMenuContext.Consumer>
                {
                    state => <React.Fragment>

                        {!view.isMobileView && state &&
                            <Layout.Sider theme={"light"} style={{borderRight: "1px solid #e4e9f0"}}
                                          collapsed={state.collapsed}
                                          onCollapse={state.setCollapsed}>
                                {props.children}
                            </Layout.Sider>}
                        {view.isMobileView && state &&
                            <Drawer bodyStyle={{padding: 0}} visible={!state.collapsed}
                                    onClose={() => state.setCollapsed(true)} title={"App Menu"} placement={"left"}>
                                {props.children}
                            </Drawer>}
                    </React.Fragment>
                }
            </ResponsiveMenuContext.Consumer>
        }
    </ViewPortContext.Consumer>
}
export const companyIdAtomVariable = atom<number | undefined>(undefined)
export const locationIdAtomVariable = atom<number | undefined>(undefined)
export const companiesAtomVariable = atom<any[]>([]);
export const locationsAtom = atom<any[]>([]);
export const locationsAtomVariable = atom(
    (get) => get(locationsAtom),
    async (_get, set, companyID: number | undefined) => {
        if (companyID) {
            const { data: { rows = [] } = {} } = await fetch_locations(companyID);
            if (rows.length === 1) {
                set(locationIdAtomVariable, rows[0]?.id);
            }
            set(locationsAtom, rows);
        }
    }
);

export const quarterAtomVariable = atom<string>('')

export const MainView: React.FunctionComponent = (props) => {

    const [collapsed, setCollapsed] = React.useState(false)

    const [companyIdAtom, setCompanyIdAtom] = useAtom(companyIdAtomVariable);
    const setCompaniesAtom = useSetAtom(companiesAtomVariable);
    const setLocationsAtom = useSetAtom(locationsAtomVariable);

    const {md} = Grid.useBreakpoint()
    const {REACT_APP_BUILD_VERSION = ''} = process.env;
    const history = useHistory();
    const location = useLocation();

    const setView = (view: any) => {
        history.push(view)
    }

    const adminMenus = React.useMemo(() =>
        <React.Fragment>
            <Menu.SubMenu key={'dashboard'} title={<Trans i18nKey={`main_view.dashboard`} defaults={`Dashboard`} />} icon={<DashboardOutlined size={75}/>} >
                <Menu.Item key={"/view/review-dashboard"}><Trans i18nKey={`main_view.dashboard`} defaults={`Dashboard`} /></Menu.Item>
                <Menu.Item key={"/view/review_approval"}><Trans i18nKey={`main_view.review_approval`} defaults={`Review Approvals`} /></Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={"/view/inspections"} icon={<MonitorOutlined size={75}/>}><Trans i18nKey={`main_view.inspections`} defaults={`Inspections`} /></Menu.Item>
            <Menu.Item key={"/view/users"} icon={<TeamOutlined size={75}/>}><Trans i18nKey={`main_view.users`} defaults={`Users`} /></Menu.Item>
            <Menu.SubMenu key={'company'} title={<Trans i18nKey={`main_view.company`} defaults={`Company`} />} icon={<BankOutlined size={75}/>}>
                <Menu.Item key={"/view/company"}><Trans i18nKey={`main_view.registration`} defaults={`Registration`} /></Menu.Item>
                <Menu.Item key={"/view/company-acquire"}><Trans i18nKey={`main_view.acquisition`} defaults={`Acquisition`} /></Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={"/view/locations"} icon={<EnvironmentOutlined size={75}/>}><Trans i18nKey={`main_view.locations`} defaults={`Locations`} /></Menu.Item>
            <Menu.SubMenu key={"settings"} title={<Trans i18nKey={`main_view.settings`} defaults={`Settings`} />} icon={<SettingOutlined size={75}/>}>
                <Menu.Item key={"/view/settings"}><Trans i18nKey={`main_view.general_settings`} defaults={`General Settings`} /></Menu.Item>
                <Menu.Item key={"/view/global-settings"}><Trans i18nKey={`main_view.global_settings`} defaults={`Global Settings`} /></Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key={"reports"} title={<Trans i18nKey={`main_view.reports`} defaults={`Reports`} />} icon={<SnippetsOutlined size={75}/>}>
                <Menu.Item key={"/view/daily-hours-reports"}><Trans i18nKey={`main_view.daily_hours`} defaults={`Daily Hours`} /></Menu.Item>
                <Menu.Item key={"/view/summary-report"}><Trans i18nKey={`main_view.summary_reports`} defaults={`Summary Reports`} /></Menu.Item>
                <Menu.Item key={"/view/crash_reports"}><Trans i18nKey={`main_view.crash_reports`} defaults={`Crash Reports`} /></Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={"/view/downloads"} icon={<DownloadOutlined size={75}/>}><Trans i18nKey={`main_view.downloads`} defaults={`Downloads`} /></Menu.Item>
            <Menu.Item key={"/view/inspection_export"} icon={<ExportOutlined size={75}/>}><Trans i18nKey={`main_view.inspection_export`} defaults={`Inspection Export`} /></Menu.Item>
            <Menu.Item key={"/view/audit-log"} icon={<MenuUnfoldOutlined size={75}/>}><Trans i18nKey={`main_view.audit_trial`} defaults={`Audit Trail`} /></Menu.Item>
        </React.Fragment>, [])

    const userMenu = React.useMemo(() =>
        <React.Fragment>
            <Menu.Item key={"/view/review_request"} icon={<DashboardOutlined />}><Trans i18nKey={`main_view.review_request`} defaults={`Review Requests`} /></Menu.Item>
            <Menu.Item key={"/view/inspections"} icon={<MonitorOutlined size={75}/>}><Trans i18nKey={`main_view.inspections`} defaults={`Inspections`} /></Menu.Item>
            <Menu.Item key={"/view/users"} icon={<TeamOutlined size={75}/>}><Trans i18nKey={`main_view.users`} defaults={`Users`} /></Menu.Item>
            <Menu.Item key={"/view/settings"} icon={<SettingOutlined size={75}/>}><Trans i18nKey={`main_view.settings`} defaults={`Settings`} /></Menu.Item>
            <Menu.SubMenu key={"reports"} title={<Trans i18nKey={`main_view.reports`} defaults={`Reports`} />} icon={<SnippetsOutlined size={75}/>}>
                <Menu.Item key={"/view/daily-hours-reports"}><Trans i18nKey={`main_view.daily_hours`} defaults={`Daily Hours`} /></Menu.Item>
                <Menu.Item key={"/view/summary-report"}><Trans i18nKey={`main_view.summary_reports`} defaults={`Summary Reports`} /></Menu.Item>
                <Menu.Item key={"/view/crash_reports"}><Trans i18nKey={`main_view.crash_reports`} defaults={`Crash Reports`} /></Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={"/view/downloads"} icon={<DownloadOutlined size={75}/>}><Trans i18nKey={`main_view.downloads`} defaults={`Downloads`} /></Menu.Item>
            <Menu.Item key={"/view/inspection_export"} icon={<ExportOutlined size={75}/>}><Trans i18nKey={`main_view.inspection_export`} defaults={`Inspection Export`} /></Menu.Item>
        </React.Fragment>, [])

    const inspectorMenu = React.useMemo(() =>
        <React.Fragment>
            <Menu.Item key={"/view/downloads"} icon={<DownloadOutlined size={75}/>}><Trans i18nKey={`main_view.downloads`} defaults={`Downloads`} /></Menu.Item>
        </React.Fragment>, [])

    function renderMenu(auth: any) {
        if (auth.user !== undefined) {
            const user_role = auth.user.user_role;
            switch (user_role) {
                case "administrator":
                    return adminMenus
                case "inspector":
                    return inspectorMenu
                default:
                    return userMenu
            }
        }
        return inspectorMenu
    }

    function renderAvatar(auth: any) {
        if (auth.user !== undefined) {
            const user = auth.user;
            return user.avatar;
        } else {
            return 'QC'
        }
    }
    const fetchCompanies = async() =>{
        const {data: {rows = []} = {}} = await fetch_company();
        if(rows.length===1){
            unstable_batchedUpdates(()=>{
                setCompaniesAtom(rows);
                setCompanyIdAtom(rows[0].id);
            })
        }
        else{
            setCompaniesAtom(rows);
        }
    }

    const fetchLocations = async() =>{
        await setLocationsAtom(companyIdAtom);
    }

    useEffect(()=>{
        fetchCompanies()
    },[])

    useEffect(()=>{
        fetchLocations()
    },[companyIdAtom])

    return <React.Fragment>
        <ViewPortContext.Provider value={{isMobileView: !md}}>
            <Layout style={{height: '100%'}}>

                <ResponsiveMenuContext.Provider value={{collapsed, setCollapsed}}>
                    <ResponsiveMenu>
                        {md &&
                            <div className={"ant-layout-header"}
                                 style={{backgroundColor: "transparent", padding: 0, textAlign: 'center'}}>
                                <a href={"https://sbcindustry.com/digital-qc"} target={"_blank"} rel={"noreferrer"}>
                                    <img src={collapsed?squareImage:fullImage} className={"ant-layout-header"}
                                         style={{backgroundColor: "transparent", padding: 0, height: "auto", maxWidth: collapsed?75:150}} alt={"logo"}/>
                                </a>
                            </div>}

                        <div className={'scroll-panel'} style={{height: 'calc(100% - 64px)'}}>
                            <PerfectScrollbar component={"div"} options={{swipeEasing: true, wheelSpeed: 0.25}}>
                                <AuthContext.Consumer>{auth =>
                                    <Menu
                                        mode={"inline"}
                                        onSelect={(e) => setView(e.key)}
                                        selectedKeys={[location.pathname]}
                                        defaultOpenKeys={["orders", "invoices", "reports"]}
                                    >
                                        {renderMenu(auth)}
                                    </Menu>

                                }</AuthContext.Consumer>

                            </PerfectScrollbar>
                        </div>
                    </ResponsiveMenu>
                </ResponsiveMenuContext.Provider>
                <Layout>
                    <Layout.Header style={{
                        backgroundColor: "white",
                        borderBottom: "1px solid #e4e9f0",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 16
                    }}>
                        <Button className={"custom-text-btn"} size={"large"} icon={<MenuOutlined size={75}/>}
                                type={"link"} onClick={() => setCollapsed(!collapsed)}/>
                        {md &&
                            <AuthContext.Consumer>{auth =>
                                <Menu
                                    style={{minWidth: '80vw'}}
                                    mode={"horizontal"}
                                    onSelect={(e) => setView(e.key)}
                                    selectedKeys={[location.pathname]}
                                >
                                    {renderMenu(auth)}
                                </Menu>
                            }</AuthContext.Consumer>
                        }
                        <div style={{flexGrow: 1, height: '100%'}}/>
                        <Menu mode={"horizontal"} selectedKeys={[location.pathname]} onSelect={(e) => {
                            if (e.key && e.key.startsWith('/view'))
                                setView(e.key)
                        }}>
                            <AuthContext.Consumer>{auth =>
                                <Menu.SubMenu title={<Avatar>{renderAvatar(auth)}</Avatar>}>
                                    <Menu.Item key={"/view/profile"} id={'profile'}><Trans i18nKey={`main_view.profile`} defaults={`Profile`} /></Menu.Item>
                                    <Menu.SubMenu title={<Trans i18nKey={`main_view.account_settings`} defaults={`Account Setting`} />}>
                                        <Menu.Item key={"/view/reset-password"}><Trans i18nKey={`main_view.change_password`} defaults={`Change password`} id={'change_password'} /></Menu.Item>
                                    </Menu.SubMenu>
                                    <Menu.Item onClick={()=>auth.auth.signOut()} id={'sign_out'}><Trans i18nKey={`main_view.sign_out`} defaults={`Sign Out`} /></Menu.Item>
                                </Menu.SubMenu>}
                            </AuthContext.Consumer>
                        </Menu>
                    </Layout.Header>
                    <AuthContext.Consumer>{auth =>
                        <ConfigsContextProvider>
                            <Layout.Content style={{backgroundColor: "white", padding: 12, maxHeight: '100%', overflowY: 'auto'}} className={'scroll-panel'}>
                                <Route path="/view/locations">
                                    <Validate role={["administrator"]} warn={true}>
                                        <Locations fetchCompanies={fetchCompanies} fetchLocations={fetchLocations}/>
                                    </Validate>
                                </Route>
                                <Route path="/view/company">
                                    <Validate role={["administrator"]} warn={true}>
                                        <Company fetchCompanies={fetchCompanies} fetchLocations={fetchLocations}/>
                                    </Validate>
                                </Route>
                                <Route path="/view/company-acquire">
                                    <Validate role={["administrator"]} warn={true}>
                                        <CompanyAcquisition/>
                                    </Validate>
                                </Route>
                                <Route path="/view/global-settings">
                                    <Validate role={["administrator"]} warn={true}>
                                        <CertifiedSettings type={"global"}/>
                                    </Validate>
                                </Route>
                                <Route path="/view/audit-log">
                                    <Validate role={["administrator"]} warn={true}>
                                        <AuditTrail/>
                                    </Validate>
                                </Route>
                                <Route path="/view/review-dashboard">
                                    <Validate role={["administrator"]} warn={true}>
                                        <Dashboard />
                                    </Validate>
                                </Route>
                                <Route path="/view/review_approval">
                                    <Validate role={["administrator"]} warn={true}>
                                        <ReviewApprovals />
                                    </Validate>
                                </Route>
                                <Route path="/view/review_request">
                                    <Validate role={["district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <ReviewRequests  />
                                    </Validate>
                                </Route>
                                <Route path="/view/send-mail">
                                    <Validate role={["administrator"]} warn={true}>
                                        <SendMail/>
                                    </Validate>
                                </Route>
                                <Route path="/view/review-comments">
                                    <Validate role={["administrator"]} warn={true}>
                                        <ReviewComments />
                                    </Validate>
                                </Route>
                                <Route path="/view/inspection-comments">
                                    <Validate role={["administrator"]} warn={true}>
                                        <ReviewOOCComments />
                                    </Validate>
                                </Route>
                                <Route path="/view/review-ooc-comments">
                                    <Validate role={["administrator"]} warn={true}>
                                        <ReviewOOCComments />
                                    </Validate>
                                </Route>
                                <Route path="/view/fetch_span_data">
                                    <Validate role={["administrator"]} warn={true}>
                                        <SpanBreakdown />
                                    </Validate>
                                </Route>
                                <Route path="/view/users">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <Users/>
                                    </Validate>
                                </Route>
                                <Route path="/view/settings">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <Settings />
                                    </Validate>
                                </Route>
                                <Route path="/view/inspections">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <Inspections >
                                        </Inspections>
                                    </Validate>
                                </Route>
                                <Route path="/view/daily-hours-reports">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <DailyHours >
                                        </DailyHours>
                                    </Validate>
                                </Route>
                                <Route path="/view/add-daily-hours">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <AddDailyHours />
                                    </Validate>
                                </Route>
                                <Route path="/view/add-weekly-hours">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <AddWeeklyHours />
                                    </Validate>
                                </Route>
                                <Route path="/view/summary-report">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <SummaryReport />
                                    </Validate>
                                </Route>
                                <Route path="/view/report-pdf">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <ReportPDF />
                                    </Validate>
                                </Route>
                                <Route path="/view/crash_reports">
                                    <Validate role={["administrator", "district_manager", "regional_manager", "location_qc_manager", "qc_manager"]} warn={true}>
                                        <CrashReports/>
                                    </Validate>
                                </Route>
                                <Route path="/view/downloads">
                                    <Downloads/>
                                </Route>
                                <Route path="/view/inspection_export">
                                    <Validate role={["administrator"
                                        , "district_manager", "regional_manager", "location_qc_manager", "qc_manager"
                                    ]} warn={true}>
                                        <InspectionExport/>
                                    </Validate>
                                </Route>
                                <Route path="/view/reset-password">
                                    <ResetPassword/>
                                </Route>
                                <Route path="/view/profile">
                                    <Profile/>
                                </Route>
                            </Layout.Content>
                        </ConfigsContextProvider>}
                    </AuthContext.Consumer>
                    <Layout.Footer style={{backgroundColor: "white", borderTop: "1px solid #e4e9f0", zIndex: 1}}>
                        <Space direction="horizontal" style={{width: '100%', justifyContent: 'center'}}>
                            <Typography.Text strong italic
                                             >{`Copyright © ${(new Date().getFullYear())} by SBCA Server Build ${REACT_APP_BUILD_VERSION}. All Rights Reserved.`}</Typography.Text>
                        </Space>
                    </Layout.Footer>
                </Layout>
            </Layout>
        </ViewPortContext.Provider>
    </React.Fragment>
}
