import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import esES from "antd/lib/locale/es_ES";
import {DailyHourItem, DailyHoursData} from "../components/AddWeeklyHours";
export const writeToCSV = (res: any, fileName: string) => {
    const dataCSV = res.data;
    const filename = fileName;
    const blob = new Blob([dataCSV], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export const languageMap = (language: any) => {
    switch (language) {
        case 'en':
            return enUS;
        case 'fr':
            return frFR;
        case 'es':
            return esES;
        default:
            return enUS;
    }
}
export const createEmptyDailyHoursData = (key: string): DailyHoursData => ({
    key,
    setup: '',
    shift: '',
    crew: '',
    week: null,
    sun: null,
    mon: null,
    tue: null,
    wed: null,
    thu: null,
    fri: null,
    sat: null,
})

export const processDailyHours = (hourData: DailyHoursData[], companyID: number | undefined, locationID: number | undefined) => {
    let dailyHours = {
        company_id: companyID,
        location_id: locationID,
        data: [] as DailyHourItem[]
    };
    const validatedRows = validateHours(hourData)
    if (!validatedRows.error) {
        for (let row of validatedRows.data) {
            dailyHours.data.push({
                shift_id: row.shift || '',
                line_id: row.setup || '',
                crew_id: row.crew || '',
                week: row?.week?.format('YYYY-MM-DD') || null,
                hours: [
                    row.sun !== null && row.sun !== undefined ? parseFloat(row.sun) : null,
                    row.mon !== null && row.mon !== undefined ? parseFloat(row.mon) : null,
                    row.tue !== null && row.tue !== undefined ? parseFloat(row.tue) : null,
                    row.wed !== null && row.wed !== undefined ? parseFloat(row.wed) : null,
                    row.thu !== null && row.thu !== undefined ? parseFloat(row.thu) : null,
                    row.fri !== null && row.fri !== undefined ? parseFloat(row.fri) : null,
                    row.sat !== null && row.sat !== undefined ? parseFloat(row.sat) : null
                ]
            })
        }
    }
    return {
        dailyHours: dailyHours,
        error: validatedRows.error
    };
}

const validateHours = (hourData: DailyHoursData[]) => {
    let nonEmptyRows = [];
    let errorMessage;
    for (let datum of hourData) {
        const isEmptyRow = !(datum.shift || datum.setup || datum.crew || datum.week ||
            datum.sun || datum.mon || datum.tue || datum.wed ||
            datum.thu || datum.fri || datum.sat);
        if (!isEmptyRow) {
            nonEmptyRows.push(datum)
        }
    }
    for (let datum of nonEmptyRows) {
        if (!(datum.shift && datum.setup && datum.crew && datum.week)) {
            errorMessage = 'add_daily_hours.add_all_data'
            break
        }
        const allHoursEmpty = [datum.sun, datum.mon, datum.tue, datum.wed, datum.thu, datum.fri, datum.sat]
            .some(hour => hour === null || hour === "");
        if (allHoursEmpty) {
            errorMessage = 'add_daily_hours.warning_add_hours'
            break
        }
    }
    return {
        data: nonEmptyRows,
        error: errorMessage
    }
}