import React, {Fragment, useCallback, useEffect, useState, useRef} from "react";
import {
    clearDefault,
    configs,
    fetch,
    fetchQCManual,
    FetchRequest,
    insert,
    insertOneForEachCompanyLocation,
    update,
    updateDefault,
    uploadQCManual
} from "../api";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Form as AntdForm,
    Input,
    InputNumber,
    message,
    Modal,
    notification,
    PageHeader,
    Popconfirm,
    Radio,
    Row,
    Space,
    Tabs,
    Typography,
    Upload
} from "antd";
import {CheckOutlined, CloseOutlined, EditOutlined, FilePdfOutlined, UploadOutlined} from "@ant-design/icons";
import {ConfigResponse, Response, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {Form} from "@q4us-sw/q4us-ui";
import {unstable_batchedUpdates} from "react-dom";
import CertifiedSettings from "./CertifiedSettings";
import {AuthContext} from "./user/AuthProvider";
import {getOrdering} from "./filters/ordering";
import {LocationsFilter, LocationsFilterBaseProps} from "./filters/LocationsFilter";
import {handleScroll} from "../utils/util";
import SingleSelectCustomCheckBox from "./lib/SingleSelectCustomCheckBox";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {Trans} from "./lib/CustomTransComponent";
import {useTranslation} from "react-i18next";
import {useAtom, useAtomValue} from "jotai";
import {
    companyIdAtomVariable,
    locationIdAtomVariable,
    locationsAtomVariable
} from "../view/MainView";

interface HeaderProps {
    title: string
    schema: string
    company_id?: number
    location_id?: number
    defAvailSetting: any
}

export interface SettingsProps extends LocationsFilterBaseProps{

}

const fetchSettings = (request:FetchRequest, companyID?:number, locationID?:number) => {
    let modifiedFilter = [{name: 'company_id', value: companyID}, {name: 'location_id', value: locationID}];
    if (request.filter){
        modifiedFilter = modifiedFilter.concat(request.filter);
    }
    return fetch({...request, filter:modifiedFilter});
}

const getDataPayload = (request:any, schema:string, filterOverride?: any) => {
    const column = request?.options?.inbuilt?.sort.column || 'id'
    const direction = request?.options?.inbuilt?.sort.direction || 'asc'
    const orderBy = getOrdering(column, direction);
    return {
        tableName: schema,
        orderBy: orderBy,
        filter: filterOverride || request?.options?.values,
        page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
    }
}

const Header: React.FunctionComponent<HeaderProps> = ({title, schema, company_id, location_id, defAvailSetting}) => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    const openSetupDialog = (company_id: any, location_id: any) => {
        if (company_id && location_id) {
            setOpen(true)
        } else {
            notification.info({message: <Trans i18nKey={`general_settings_page.select_company_and_location`} defaults={`Please select company and location first`}/>})
        }
    }

    return <div style={{paddingRight: 7, width: '100%'}}>
        <Row style={{textAlign: 'right'}}>
            <Col span={12}>
                <PageHeader title={title}/>
            </Col>
            <Col span={12}>
                <TableContext.Consumer>{
                    table=><React.Fragment>
                        <Space>
                            <Checkbox
                                onChange={async (e) => {
                                    table?.setLoading(true);
                                    let modified = undefined;
                                    if(e.target.checked){
                                        modified = [{...table?.customData?.values, ...{name:'is_active', value:e.target.checked}}];
                                    }
                                    else{
                                        modified = table?.customData?.values?.filter((item:any)=>item.name!=='is_active');
                                    }
                                    const request = getDataPayload({options:table?.customData, page:table?.page}, schema, modified);
                                    const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                    table?.setCustomData({...table?.customData, values:modified});
                                    table?.setData(rows);
                                    table?.setPage({
                                        current: page?.current ?? table?.page?.current ?? 0,
                                        total: page?.total ?? table?.page?.total ?? 0,
                                        size: page?.size ?? table?.page?.size ?? 100
                                    });
                                    table?.setLoading(false);
                                }}
                            ><Trans i18nKey={`general_settings_page.hide_inactive`} defaults={`Hide Inactive`} /></Checkbox>
                            <Button type={"primary"} onClick={()=>{openSetupDialog(company_id, location_id)}}>{`${t('general_settings_page.add', 'Add')} ${title}`}</Button>
                            <Button onClick={async () => {
                                if (company_id && location_id){
                                    const {data: {statusCode, message}} = await clearDefault({
                                        tableName: schema,
                                        company_id,
                                        location_id
                                    })
                                    if(statusCode===200){
                                        table?.setLoading(true);
                                        const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                        const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                        table?.setData(rows);
                                        table?.setPage({
                                            current: page?.current ?? table?.page?.current ?? 0,
                                            total: page?.total ?? table?.page?.total ?? 0,
                                            size: page?.size ?? table?.page?.size ?? 100
                                        });
                                        table?.setLoading(false);
                                        defAvailSetting.current = false;
                                        notification.success({message: <Trans i18nKey={`general_settings_page.default_reset`} defaults={`Default has reset`} />})
                                    } else{
                                        notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                                    }
                                } else{
                                    notification.info({message: <Trans i18nKey={`general_settings_page.select_company_and_location`} defaults={`Please select company and location first`}/>})
                                }
                            }}><Trans i18nKey={`general_settings_page.clear_default`} defaults={`Clear default`} /></Button>
                        </Space>
                        <Modal
                            title={`${t('general_settings_page.add_new', 'Add New')} ${title}`}
                            width={'50vw'}
                            visible={open}
                            onCancel={()=>setOpen(false)}
                            footer={null}
                            destroyOnClose
                        >
                            <Form
                                schema={schema}
                                initialValue={{is_active: true}}
                                maxHeight={'60vh'}
                                config={async () => {
                                    const res = await configs(schema)
                                    return res.data.rows;
                                }}
                                submit={async (values:any) => {
                                    if(company_id && location_id){
                                        const {data: {statusCode, message} = {} } = await insert({
                                            tableName: schema,
                                            data: {...values, company_id, location_id}
                                        })
                                        if(statusCode===200){
                                            table?.setLoading(true);
                                            const request = getDataPayload({options:table?.customData, page:table?.page},schema)
                                            const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                            table?.setData(rows);
                                            table?.setPage({
                                                current: page?.current ?? table?.page?.current ?? 0,
                                                total: page?.total ?? table?.page?.total ?? 0,
                                                size: page?.size ?? table?.page?.size ?? 100
                                            });
                                            table?.setLoading(false);
                                            notification.success({message: <Trans i18nKey={`general_settings_page.success`} defaults={`Success`}/>})
                                            setOpen(false)
                                            return true
                                        } else{
                                            notification.error({message: `${t('general_settings_page.error_in_creating')} ${title}` || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                                            return false
                                        }
                                    } else{
                                        notification.info({message: <Trans i18nKey={`general_settings_page.please_select_company_and_location`} defaults={`Please select company and location first`} />})
                                        return false
                                    }
                                }}
                                validateField={(rule: any, value: any, schema: string, column: FormElementProps, form: FormInstance<any>) => {
                                    switch (column.name) {
                                        case "is_default": {
                                            if (form.getFieldValue('is_default') && defAvailSetting.current
                                            ) {
                                                return Promise.reject(<Trans i18nKey={`general_settings_page.default_value_exist`} defaults={`Default value already available`} />)
                                            }
                                        }
                                    }
                                    return Promise.resolve()
                                }}
                            />
                        </Modal>
                    </React.Fragment>
                }</TableContext.Consumer>
            </Col>
        </Row>
        {title==='Setups'&&<Row style={{margin: 10}}>
            <Alert
                message={<Trans i18nKey={`general_settings_page.setup_alert-message`} defaults={`When selecting a Truss Type, Jack tables are considered Roof Trusses and Splice tables are neither.`} />}
                type="info"
                showIcon
            />
        </Row>}
    </div>
}

interface FieldEditProps {
    schema: string
    value: any,
    row: any
    index: number
    companyID?: number
    locationID?: number
}

const FieldEdit: React.FunctionComponent<FieldEditProps> = ({schema,value, row, index, companyID, locationID}) => {
    const [isEdit, setIsEdit] = useState(false)
    const [currentRow, setCurrentRow] = useState<number>()
    const [currentValue, setCurrentValue] = useState<string>()
    const {t} = useTranslation()

    const {Text} = Typography

    const btnStyle: any = {margin: 5, float: "left"}

    return <TableContext.Consumer>
        {table => <Fragment>
            {
                (isEdit && index===currentRow)?
                    <Fragment>
                        <Button style={btnStyle} icon={<CheckOutlined />} onClick={async () => {
                            const {data: {statusCode, message}} = await update({
                                tableName: schema,
                                data: {...row, name: currentValue}
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, companyID, locationID);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: `${row?.name} ${t('general_settings_page.changed_as')} ${currentValue}`})
                                setIsEdit(false)
                            } else{
                                notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                            }
                        }}/>
                        <Button style={btnStyle} icon={<CloseOutlined />} onClick={() => {
                            setIsEdit(false)
                        }}/>
                    </Fragment>
                    :
                    <Button style={btnStyle} icon={<EditOutlined />} onClick={() => {
                        unstable_batchedUpdates(() => {
                            setCurrentRow(index)
                            setIsEdit(true)
                        })
                    }}/>
            }
            {
                (isEdit && index===currentRow)?
                    <Input
                        style={{margin: 5, maxWidth: 200, float: "left"}}
                        defaultValue={value}
                        onChange={((e) => {
                            setCurrentValue(e.target.value)
                        })}
                    />
                    :
                    <Text style={{margin: 5, float: "left"}}>{value}</Text>
            }
        </Fragment>}
    </TableContext.Consumer>
}

interface SettingProps {
    title: string,
    name: string,
    schema: string
    company_id?: number
    location_id?: number
    defAvailObj: Object
}

const Setting: React.FunctionComponent<SettingProps> = ({title, name, schema, company_id, location_id, defAvailObj}) => {
    const defAvailSetting: any = defAvailObj[title as keyof typeof defAvailObj]
    const {t} = useTranslation()
    return <TableV2
        schema={schema}
        header={<Header title={t(`general_settings_page.${title}`, title)} schema={schema} company_id={company_id} location_id={location_id} defAvailSetting={defAvailSetting}/>}
        fetchConfig={async () => {
            const response = await configs(schema) || {}
            response.data.rows = response.data.rows.map((row: any) => {
                if (row.name) {
                    row.title = <Trans i18nKey={`${schema}_table.${row.name}`} defaults={row.title}/>;
                }
                return row;
            });
            return response as ConfigResponse
        }}
        fetchData={async (request) => {
            handleScroll()
            const payload = getDataPayload(request, schema)
            const response = await fetchSettings(payload, company_id, location_id)
            return response as Response
        }}
        scroll={{y: 'calc(100vh - 585px)', x: '100%'}}
        customRenderer={{
            name: (value, row, index, column) => <FieldEdit schema={schema} value={value} row={row} index={index} companyID={company_id} locationID={location_id}/>,
            is_active: (value, row, index, column) => {
                return <TableContext.Consumer>
                    {table => <Checkbox
                        checked={value}
                        onChange={async (e) => {
                            const {data: {statusCode, message}} = await update({
                                tableName: schema,
                                data: {...row, is_active: e.target.checked}
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: `${row?.name} ${e.target.checked? t('general_settings_page.activated') : t('general_settings_page.inactivated')}`})
                            } else{
                                notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                            }
                        }}
                    />}
                </TableContext.Consumer>
            },
            is_default: (value, row, index, column) => {
                if (value == true) {
                    // To set the settingRef to true if at least one row is default.
                    defAvailSetting.current = true;
                }
                return <TableContext.Consumer>
                    {table => <Radio checked={value} onChange={async () => {
                        if (company_id && location_id){
                            const {data: {statusCode, message}} = await updateDefault({
                                tableName: schema,
                                id: row?.id,
                                company_id,
                                location_id
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: <Trans i18nKey={`general_settings_page.set_as_default`} values={{name: row?.name}} defaults={`${row?.name} set as default`} />})
                            } else{
                                notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                            }
                        } else{
                            notification.info({message: <Trans i18nKey={`general_settings_page.select_company_and_location`} defaults={`Please select company and location first`}/>})
                        }
                    }}/>}
                </TableContext.Consumer>
            },
            truss_type: (value, row, index, column) =>{
                return <TableContext.Consumer>{table=><SingleSelectCustomCheckBox
                    schema={'general_settings_page'}
                    value={value}
                    options={column.enums||[]}
                    onChange={async (value) => {
                        const {data: {statusCode, message}} = await update({
                            tableName: schema,
                            data: {...row, truss_type: value}
                        })
                        if(statusCode===200){
                            const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                            const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                            table?.setData(rows);
                            table?.setPage({
                                current: page?.current ?? table?.page?.current ?? 0,
                                total: page?.total ?? table?.page?.total ?? 0,
                                size: page?.size ?? table?.page?.size ?? 100
                            });
                            notification.success({message: <Trans i18nKey={`general_settings_page.updated`} values={{name: row?.name}} defaults={`${row?.name} Updated`} />})
                        } else{
                            notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                        }
                    }}/>}</TableContext.Consumer>
            }
        }}
    />
}

interface GeneralProps {
    company_id?: number
    location_id?: number
}

interface FieldData {
    company_id: number | undefined
    location_id: number | undefined
    id?: number
    start_day_of_week?: number | null
    floor_span_tolerance?: number | null
    floor_height_tolerance?: number | null
    roof_span_tolerance?: number | null
    roof_height_tolerance?: number | null
}

const General: React.FunctionComponent<GeneralProps> = ({company_id, location_id}) => {
    const [fieldData, setFieldData] = useState<FieldData>({company_id, location_id});
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const handleInputChange = (value: any, key: any) => {
        setFieldData({ ...fieldData, [key]: value });
        const isAnyInputFilled = Object.values(fieldData).some(val => val !== null);
        setIsInputEmpty(!isAnyInputFilled);
    };

    const getStatus = (value?: number | null) => {
        if(value) {
            return value < 0 ? 'error' : '';
        } else {
            return '';
        }
    };

    const load = async () => {
        const {data: {rows = []} = {}} = await fetch({
            tableName: 'general',
            filter: [
                {name: 'company_id', value: company_id},
                {name: 'location_id', value: location_id}
            ]
        })
        setFieldData({...fieldData, ...rows[0]})
    }

    useEffect(() => {
        load()
    }, [])

    const {Text, Title} = Typography;

    const daysOfWeek = Object.freeze({
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    })

    const clear = () => {
        setFieldData({
            ...fieldData,
            start_day_of_week: null,
            floor_span_tolerance: null,
            floor_height_tolerance: null,
            roof_span_tolerance: null,
            roof_height_tolerance: null,
        })
        setIsInputEmpty(true);
    }

    const hasError = Object.values(fieldData).some((val) =>
        {
            return val ? val < 0 : false;
        }
    );

    return <Fragment>
        <PageHeader title={<Trans i18nKey={`general_settings_page.general`} defaults={`General`}/>}/>
        <Title level={4}><Trans i18nKey={`general_settings_page.interface`} defaults={`Interface`}/></Title>
        <Space style={{margin: 5, marginBottom: 20}}>
            <Text strong><Trans i18nKey={`general_settings_page.starting_of`} defaults={`Starting day of the week`}/></Text>
            <Radio.Group value={fieldData?.start_day_of_week} onChange={(e) => setFieldData({...fieldData, start_day_of_week: e.target.value})}>
                {
                    Object.entries(daysOfWeek)
                        .map(
                            ([key, value]) =>
                                <Radio.Button style={{color: key==='0'?'red':undefined}} value={parseInt(key)}><Trans i18nKey={`general_settings_page.${value}`} defaults={value} /></Radio.Button>
                        )
                }
            </Radio.Group>
        </Space>
        <Title level={4}><Trans i18nKey={`general_settings_page.plant_specified_tolerances`} defaults={`Plant Specified Tolerances`}/></Title>
        <Text><Trans i18nKey={`general_settings_page.tolerance_length`} defaults={`(ANSI/TPI 1 Tolerances: Length/Span ¾ In; Height ½ In)`}/></Text><br/>
        <Text><Trans i18nKey={`general_settings_page.commentary_recommendation`} defaults={`(ANSI/TPI 1 Commentary Recommendations for Floor Trusses: Length/Span ¼ In, Height 1/8 In)`}/></Text>
        <Row style={{marginTop: 15, marginLeft: '15vw', maxWidth: '50vw'}}>
            <Col span={12}>
                <Title level={5}><Trans i18nKey={`general_settings_page.floor`} defaults={`Floor`} /></Title>
                <Space style={{margin: 5}}>
                    <Row>
                        <Col span={11}>
                            <Text strong><Trans i18nKey={`general_settings_page.span_tolerance`} defaults={`Span Tolerance`}/></Text>
                        </Col>
                        <Col span={13}>
                            <InputNumber
                                value={fieldData?.floor_span_tolerance}
                                onChange={(value) => handleInputChange(value, 'floor_span_tolerance')}
                                addonAfter="/16ths"
                                status={getStatus(fieldData.floor_span_tolerance)}
                            />
                        </Col>
                    </Row>
                </Space>
                <Space style={{margin: 5}}>
                    <Row>
                        <Col span={11}>
                            <Text strong><Trans i18nKey={`general_settings_page.height_tolerance`} defaults={`Height Tolerance`}/></Text>
                        </Col>
                        <Col span={13}>
                            <InputNumber
                                value={fieldData?.floor_height_tolerance}
                                onChange={(value: number | null) => handleInputChange(value, 'floor_height_tolerance')}
                                addonAfter="/16ths"
                                status={getStatus(fieldData.floor_height_tolerance)}
                            />
                        </Col>
                    </Row>
                </Space>
            </Col>
            <Col span={12}>
                <Title level={5}><Trans i18nKey={`general_settings_page.roof`} defaults={`Roof`} /></Title>
                <Space style={{margin: 5}}>
                    <Row>
                        <Col span={11}>
                            <Text strong><Trans i18nKey={`general_settings_page.span_tolerance`} defaults={`Span Tolerance`}/></Text>
                        </Col>
                        <Col span={13}>
                            <InputNumber
                                value={fieldData?.roof_span_tolerance}
                                onChange={(value: number | null) => handleInputChange(value, 'roof_span_tolerance')}
                                addonAfter="/16ths"
                                status={getStatus(fieldData.roof_span_tolerance)}
                            />
                        </Col>
                    </Row>
                </Space>
                <Space style={{margin: 5}}>
                    <Row>
                        <Col span={11}>
                            <Text strong><Trans i18nKey={`general_settings_page.height_tolerance`} defaults={`Height Tolerance`}/></Text>
                        </Col>
                        <Col span={13}>
                            <InputNumber
                                value={fieldData?.roof_height_tolerance}
                                onChange={(value: number | null) => handleInputChange(value, 'roof_height_tolerance')}
                                addonAfter="/16ths"
                                status={getStatus(fieldData.roof_height_tolerance)}
                            />
                        </Col>
                    </Row>
                </Space>
            </Col>
        </Row>
        <Space style={{margin: 10, marginTop: 20, marginRight: '20vw', float: "right"}}>
            <Button onClick={() => {
                clear()
            }}>Clear</Button>
            <Button disabled={isInputEmpty || hasError} type={"primary"} onClick={async () => {
                if(company_id && location_id){
                    const {data: {statusCode, message} = {} } = await insertOneForEachCompanyLocation({
                        tableName: 'general',
                        data: fieldData
                    })
                    if(statusCode===200){
                        notification.success({message: <Trans i18nKey={`general_settings_page.settings_saved_successfully`} defaults={`Settings are saved successfully`}/>})
                    } else{
                        notification.error({message: message || <Trans i18nKey={`general_settings_page.error`} defaults={`Error`} />})
                    }
                } else{
                    notification.info({message: <Trans i18nKey={`general_settings_page.select_company_and_location`} defaults={`Please select company and location first`}/>})
                    return false
                }
            }}><Trans i18nKey={`general_settings_page.save`} defaults={`Save`} /></Button>
        </Space>
    </Fragment>
}

interface File {
    oid: number
    filename: string
}

interface UploadQCManualProps {
    id: number | undefined
}

const UploadQCManual: React.FunctionComponent<UploadQCManualProps> = ({id}) => {
    const [form] = AntdForm.useForm()
    const [open, setOpen] = useState<boolean>()
    const [file, setFile] = useState<File>()

    const loadQCFile = useCallback(async () => {
        if(id) {
            const {data: {rows: [row], rowCount}} = await fetch({tableName: 'locations', filter: [{name: 'id', value: id}]})
            if(rowCount>0) {
                setFile({oid: row.oid, filename: row.filename})
            } else {
                return Promise.reject(<Trans i18nKey={`general_settings_page.error_fetching_location_data`} defaults={`Error from fetching location data`}/>)
            }
        }
    }, [id])

    useEffect(() => {
        loadQCFile()
    }, [id])

    const handleSubmit = useCallback(async () => {
        await form.validateFields()
        const {status} = await update({tableName: 'locations', data: {id, ...file}})
        if(status===200) {
            notification.success({message: <Trans i18nKey={`general_settings_page.file_saved`} defaults={`File Saved`}/>})
            setOpen(false)
        }
    }, [id, form, file])

    const handleCancel = useCallback(async () => {
        form.resetFields()
        setFile(undefined);
        setOpen(false)
    }, [form])

    const { Item } = AntdForm
    return <Fragment>
        <Space style={{width: "max-content"}}>
            <Typography.Link disabled={!file || !file.oid || !id} onClick={() =>fetchQCManual(file?file.oid:0, file?file.filename:'')}>
                {file?.filename?file.filename:'QC Manual'} <FilePdfOutlined />
            </Typography.Link>
            {file?.filename?<Popconfirm
                title={<Trans i18nKey={`general_settings_page.qc_manual_exist`} defaults={`QC Manual already exists. Do you want to replace it?`} />}
                onConfirm={() => setOpen(true)}
                okText={<Trans i18nKey={`general_settings_page.yes`} defaults={`Yes`} />}
                cancelText={<Trans i18nKey={`general_settings_page.no`} defaults={`No`} />}
            >
                <Button disabled={!id} icon={<UploadOutlined/>}><Trans i18nKey={`general_settings_page.upload_qc_manual`} defaults={`Upload QC Manual`}/></Button>
            </Popconfirm>:<Button disabled={!id} icon={<UploadOutlined/>} onClick={() => setOpen(true)}><Trans i18nKey={`general_settings_page.upload_qc_manual`} defaults={`Upload QC Manual`}/></Button>}
        </Space>
        <Modal
            title={<Trans i18nKey={`general_settings_page.upload_qc_manual`} defaults={`Upload QC Manual`}/>}
            visible={open}
            onCancel={handleCancel}
            okText={<Trans i18nKey={`general_settings_page.save`} defaults={`Save`} />}
            onOk={handleSubmit}
            centered
            destroyOnClose
        >
            <AntdForm
                form={form}
                name={'upload_qc_manual'}
            >
                <Item
                    label={<Trans i18nKey={`general_settings_page.qc_manual`} defaults={`QC Manual`}/>}
                    name={'upload'}
                    extra={<Trans i18nKey={`general_settings_page.allowed_files`} defaults={`Allowed file types are .pdf, .doc, .docx`}/>}
                    rules={
                        [
                            {
                                required: true,
                                message: <Trans i18nKey={`general_settings_page.please_select_file`} defaults={`Please select a file`} />
                            }
                        ]
                    }
                >
                    <Upload
                        maxCount={1}
                        defaultFileList={file?.filename?[{uid: '1', name: file?.filename, status: "done"}]:undefined}
                        showUploadList={{showRemoveIcon: false}}
                        beforeUpload={(async uploadFile => {
                            if(uploadFile.type==='application/pdf' || uploadFile.type==='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || uploadFile.type==='application/msword') {
                                return true
                            } else {
                                message.error(<Trans i18nKey={`general_settings_page.invalid_file_type`} defaults={`Invalid file type`}/>)
                                return Upload.LIST_IGNORE
                            }
                        })}
                        customRequest={async opt => {
                            const formData = new FormData()
                            // @ts-ignore
                            formData.append(opt.file.name, opt.file)
                            const {data, status} = await uploadQCManual(formData)
                            if(status===200) {
                                message.success(<Trans i18nKey={`general_settings_page.file_uploaded`} defaults={`File Uploaded`}/>)
                                // @ts-ignore
                                setFile({oid: data, filename: opt.file.name})
                                // @ts-ignore
                                opt.onSuccess(data, opt.file)
                            }
                        }}
                    >
                        <Button><Trans i18nKey={`general_settings_page.select_a_file`} defaults={`Select a File`} /></Button>
                    </Upload>
                </Item>
            </AntdForm>
        </Modal>
    </Fragment>
}

export const Settings: React.FunctionComponent<SettingsProps> = (props) => {
    const [isCertified, setIsCertified] = useState<boolean | null>()
    const {t} = useTranslation()

    const [companyID, setCompanyID] = useAtom(companyIdAtomVariable);
    const [locationID, setLocationID] = useAtom(locationIdAtomVariable);
    const locations = useAtomValue(locationsAtomVariable);

    // Whether default values ara available.
    const defSetupAvail = useRef(false)
    const defShiftAvail = useRef(false)
    const defCrewAvail = useRef(false)

    const defAvailObj = {
        "Setups" : defSetupAvail,
        "Crews" : defShiftAvail,
        "Shifts" : defCrewAvail
    }

    const tabs = [
        {
            tabTitle: 'Setups',
            tabKey: 'lines',
            schema: 'line'
        },
        {
            tabTitle: 'Shifts',
            tabKey: 'shifts',
            schema: 'shift'
        },
        {
            tabTitle: 'Crews',
            tabKey: 'crews',
            schema: 'crew'
        }
    ]

    useEffect(() => {
        const location = locations?.find(({ id }) => id === locationID);
        setIsCertified(location?.is_certified);
    }, [locationID, locations]);

    const {TabPane} = Tabs
    return <Fragment>
        <PageHeader title={<Trans i18nKey={`general_settings_page.title`} defaults={`Settings`}/>}/>
        <Space>
            <LocationsFilter
                onChange={(location, locationList)=>{
                    defSetupAvail.current = false;
                    defShiftAvail.current = false;
                    defCrewAvail.current = false;
                }}
                disabledValidatorCompany={(user)=>{
                    return user.user_role !== 'administrator'
                }}
                disabledValidatorLocation={(user)=>{
                    return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
                }}
            />
            <UploadQCManual id={locationID}/>
        </Space>
        <AuthContext.Consumer>
            {auth => <Tabs defaultActiveKey='lines'>
                {tabs.map(({tabTitle, tabKey, schema})=><TabPane tab={`${t(`general_settings_page.${tabTitle}`)}`} key={tabKey}>
                    <Setting key={locationID} title={tabTitle} name={tabKey} schema={schema} company_id={companyID} location_id={locationID} defAvailObj={defAvailObj}/>
                </TabPane>)}
                <TabPane tab={<Trans i18nKey={`general_settings_page.general`} defaults={`General`} />} key={'general'}>
                    <General key={locationID} company_id={companyID} location_id={locationID}/>
                </TabPane>
                {auth?.user?.user_role==='qc_manager' && isCertified && <TabPane tab={<Trans i18nKey={`general_settings_page.certified`} defaults={`Certified`} />} key={'certified'}>
                    <CertifiedSettings key={locationID} type={'general'} location_id={locationID}/>
                </TabPane>}
            </Tabs>}
        </AuthContext.Consumer>
    </Fragment>
}

export default Settings;