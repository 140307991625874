import React, {Fragment, useEffect, useState} from "react";
import {Form} from "@q4us-sw/q4us-ui";
import {
    averageReport,
    certificationSummaryReport,
    commentReport,
    configs,
    fetch,
    jointReport,
    logReport,
    plantReport,
    qualityControlLog,
    summaryReport,
    summaryReportByCrew,
    weeklySummaryReport
} from "../../api";
import {Button, FormInstance, notification, PageHeader, Space, Spin} from "antd";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {unstable_batchedUpdates} from "react-dom";
import {AuthContext} from "../user/AuthProvider";
import {useHistory} from "react-router-dom";
import moment from "moment";
import AverageReport from "./AverageReport";
import SummaryReport from "./SummaryReport";
import QualityControlLog from "./QualityControlLog";
import WeeklySummaryReport from "./WeeklySummaryReport";
import {reportSubTitles, reportTitles} from "../../utils/reportConstants";
import CertificationReport from "./CertificationReport";
import CommentReport from "./CommentReport";
import JointReport from "./JointReport";
import CrewSummaryReport from "./CrewSummaryReport";
import LogReport from "./LogReport";
import DatePickerCustomWeekStart from '../lib/DatePickerCustomWeekStart';
import {DateRangePickerFormatted} from "../lib/DatePickerFormatted";
import { ConfigsContext } from "../configs/ConfigsProvider";
import {LocationsFilter, LocationsFilterBaseProps} from "../filters/LocationsFilter";
import PlantReport from "./PlantReport";
import {DISABLED_DATE} from "../../utils/constants";
import {Trans} from "../lib/CustomTransComponent";
import {useAtom, useAtomValue} from "jotai";
import {
    companiesAtomVariable,
    companyIdAtomVariable,
    locationIdAtomVariable,
    locationsAtomVariable
} from "../../view/MainView";

export interface OutOfConformance {
    count: number
    percentage: number
}

interface ReportsProps extends LocationsFilterBaseProps{

}

export interface MetaProps {
    // company: string | undefined
    // location: string | undefined
    start_date: string | undefined
    end_date: string | undefined
    // user: string | undefined
}

interface FilterProps extends LocationsFilterBaseProps{
    type: (type: string) => void
    onSubmit: (data: any) => void
    metaData: (meta: MetaProps) => void
}



const Filter: React.FunctionComponent<FilterProps> = ({type, onSubmit, metaData}) => {
    const companyID = useAtomValue(companyIdAtomVariable);
    const locationID = useAtomValue(locationIdAtomVariable);
    const locations = useAtomValue(locationsAtomVariable);
    const [isCertified, setIsCertified] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(false)
    const [meta, setMeta] = useState<MetaProps>({
        start_date: undefined,
        end_date: undefined
    })
    const [startDate, setStartDate] = useState<number>(0);

    const generalReportTypes = Object.freeze({
        average: 'Average',
        summary: 'Summary',
        quality_control: 'Quality Control',
        log_report: 'Log Report'
    })

    const certifiedReportTypes = Object.freeze({
        ...generalReportTypes,
        weekly_summary: 'Weekly Summary',
        certification_summary: 'Certification Summary',
        comment: 'Comment',
        plant: 'In Plant',
        joint: 'Joint',
        crew_summary: 'Crew Summary'
    })

    useEffect(() => {
        fetchStartDate(companyID, locationID)

    }, [locationID])

    useEffect(() => {
        const location = locations?.find(({ id }) => id === locationID);
        setIsCertified(location?.is_certified);
    }, [locationID, locations]);

    const fetchStartDate = async (company_id: number|undefined, location_id:number|undefined) => {
        const request = {
            tableName: 'general',
            filter: [{name: 'company_id', value: company_id}, {name: 'location_id', value: location_id}]
        }
        const {data: {rows} = {}} = await fetch(request);
        const start_day_of_week = rows?.[0]?.start_day_of_week || 0;
        setStartDate(start_day_of_week);
    }

    return <Spin spinning={loading} tip="Loading...">
        <AuthContext.Consumer>{auth=>
        <LocationsFilter
            disabledValidatorCompany={(user)=>{
                return user.user_role !== 'administrator'
            }}
            disabledValidatorLocation={(user)=>{
                return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
            }}
        />}
        </AuthContext.Consumer>
        <ConfigsContext.Consumer>{confs=>
        <Form
            key={isCertified}
            schema={'summary_report_filter'}
            layout={'inline'}
            config={async (schema: string) => {
                const {data:{rows = []} = {}} = await configs(schema)
                return rows;
            }}
            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                if(element.name==='report_type') {
                    // @ts-ignore
                    return <CustomFormInput
                        type={'STRING'}
                        title={'Report Type'}
                        name={'report_type'}
                        schema={'summary_report_filter'}
                        enums={Object.entries(isCertified?certifiedReportTypes:generalReportTypes)}
                    />
                }
                if(element.name==='date_range') {
                    if (form.getFieldValue('report_type') === 'log_report' || form.getFieldValue('report_type') === 'plant'){
                        // @ts-ignore
                        return <DatePickerCustomWeekStart picker={"week"} start_day={startDate} disabledDate={current => DISABLED_DATE(current)}/>
                    }
                    else{
                        return <DateRangePickerFormatted
                            disabledDate={current => DISABLED_DATE(current)}
                        />
                    }
                }
            }}
            submit={async (values: any) => {
                const {report_type, date_range} = values
                const [start_date, end_date] = date_range

                metaData({
                    ...meta,
                    start_date: moment(start_date).format(confs.dateFormat),
                    end_date: moment(end_date).format(confs.dateFormat)
                })

                if(moment.duration(end_date.diff(start_date)).asDays()>365) {
                    notification.error({message: <Trans i18nKey={'REPORTS_COMMON.NOTIFICATIONS.range_less_than_365'}>Date range should be less than 365 days</Trans>})
                    return false
                }
                onSubmit(undefined)
                if (!locationID) {
                    notification.info({message: <Trans i18nKey={'REPORTS_COMMON.NOTIFICATIONS.range_less_than_365'}>Please select company and location first</Trans>})
                    return false
                }
                const fieldData = {
                    company_id: parseInt(String(companyID)),
                    location_id: parseInt(String(locationID)),
                    report_type,
                    start_date: moment(start_date).startOf('day').toISOString(),
                    end_date: moment(end_date).endOf('day').toISOString()
                }
                let statusCode;
                let reportData;
                setLoading(true)
                switch (report_type) {
                    case 'average':
                        const averageRes = await averageReport(fieldData)
                        statusCode = averageRes?.status
                        reportData = averageRes?.data
                        break
                    case 'summary':
                        const summaryRes = await summaryReport(fieldData)
                        statusCode = summaryRes?.status
                        reportData = summaryRes?.data
                        break
                    case 'quality_control':
                        const qualityConRes = await qualityControlLog(fieldData)
                        statusCode = qualityConRes?.status
                        reportData = qualityConRes?.data
                        break
                    case 'weekly_summary':
                        const weeklySummaryRes = await weeklySummaryReport(fieldData)
                        statusCode = weeklySummaryRes?.status
                        reportData = weeklySummaryRes?.data
                        break
                    case 'certification_summary':
                        const certificationSummaryRes = await certificationSummaryReport(fieldData)
                        statusCode = certificationSummaryRes?.status
                        reportData = certificationSummaryRes?.data
                        break
                    case 'comment':
                        const commentRes = await commentReport(fieldData)
                        statusCode = commentRes?.status
                        reportData = commentRes?.data?.rows.filter((row:any) => row.comments !== null)
                        break
                    case 'joint':
                        const jointRes = await jointReport(fieldData)
                        statusCode = jointRes?.status
                        reportData = jointRes?.data
                        break
                    case 'plant':
                        const plantReportRes = await plantReport({
                            ...fieldData,
                            start_date: moment(start_date).format('YYYY-MM-DD'),
                            end_date: moment(end_date).format('YYYY-MM-DD')})
                        statusCode = plantReportRes?.status
                        reportData = plantReportRes?.data;
                        if (reportData){
                            reportData = {
                                ...reportData,
                                ...(plantReportRes?.data?.startDate && {startDate:moment(plantReportRes?.data?.startDate).format(confs.dateFormat)}),
                                ...(plantReportRes?.data?.endDate && {endDate: moment(plantReportRes?.data?.endDate).format(confs.dateFormat)})}
                        }
                        break
                    case 'crew_summary':
                        const crewSummaryRes = await summaryReportByCrew(fieldData)
                        statusCode = crewSummaryRes?.status
                        reportData = crewSummaryRes?.data
                        break
                    case 'log_report':
                        const logReportRes = await logReport({
                            ...fieldData,
                            start_date: moment(start_date).format('YYYY-MM-DD'),
                            end_date: moment(end_date).format('YYYY-MM-DD')})
                        statusCode = logReportRes?.status
                        reportData = logReportRes?.data;
                        if (reportData){
                            reportData = {
                                ...reportData,
                                ...(logReportRes?.data?.startDate && {startDate:moment(logReportRes?.data?.startDate).format(confs.dateFormat)}),
                                ...(logReportRes?.data?.endDate && {endDate: moment(logReportRes?.data?.endDate).format(confs.dateFormat)})}
                        }
                        break
                    default:
                        notification.warning({message: <Trans i18nKey={'REPORTS_COMMON.NOTIFICATIONS.invalid_report_type'}>Invalid report type identified</Trans>})
                }
                setLoading(false)
                if (statusCode === 200) {
                    type(report_type)
                    onSubmit(reportData)
                }
            }}
        />}</ConfigsContext.Consumer>
    </Spin>
}

const Reports:React.FunctionComponent<ReportsProps> = (props) => {
    const [reportType, setReportType] = useState<string>()
    const [reportData, setReportData] = useState<any>()
    const [metaData, setMetaData] = useState<MetaProps>()

    const [locationID, setLocationID] = useAtom(locationIdAtomVariable);

    const history = useHistory()

    return <Fragment>
        <PageHeader title={(reportType && <Trans i18nKey={`REPORTS_COMMON.REPORT_TITLES.${reportType}`}>{reportTitles[reportType]}</Trans>) || <Trans i18nKey={`REPORTS_COMMON.REPORT_TITLES.summary_report_title`}>Summary Reports</Trans>} subTitle={reportType && reportSubTitles[reportType] && <Trans i18nKey={`REPORTS_COMMON.REPORT_TITLES.${reportType}_subtitle`}>reportSubTitles[reportType]</Trans>}/>
        <Space>
            <Filter
                type={(type => setReportType(type))}
                onSubmit={data => setReportData(data)}
                metaData={meta => setMetaData(meta)}
            />
            <Button
                shape="round"
                disabled={!reportData}
                style={{marginBottom: 13}}
                onClick={() => {
                    if ((reportData.length === 0 && reportType === 'quality_control') ||
                        ((!reportData.inspectionFrequency || reportData.inspectionFrequency.length === 0) && reportType === 'average') ||
                        ((!reportData.overallQCInspections || reportData.overallQCInspections.total_number_inspected.trusses === 0) && reportType === 'summary')
                    ) {
                        notification.warning({message: <Trans i18nKey={'REPORTS_COMMON.NOTIFICATIONS.no_records_found'}>No records found for the selected dates</Trans>})
                    } else {
                        history.push({
                            pathname: '/view/report-pdf',
                            state: {reportType, reportData, metaData}
                        })
                    }
                }}
            >
                <Trans i18nKey={'REPORTS_COMMON.GENERATE_PDF'}>Generate PDF</Trans>
            </Button>
        </Space>
        <div>
            {reportType==='average' && reportData && <AverageReport
                inspectionInformation={reportData.inspectionInformation}
                jointBreakdown={reportData.jointBreakdown}
                dimensionBreakdown={reportData.dimensionBreakdown}
                inspectionFrequency={reportData.inspectionFrequency}
                inspectionFrequencyByCrew={reportData.inspectionFrequencyByCrew}
                setupFrequency={reportData.setupFrequency}
            />}
            {reportType==='summary' && reportData && <SummaryReport
                overallQCInspections={reportData.overallQCInspections}
                outOfConformanceVisual={reportData.outOfConformanceVisual}
                outOfConformanceDetailed={reportData.outOfConformanceDetailed}
                outOfConformanceMembers={reportData.outOfConformanceMembers}
                platesUpsized={reportData.platesUpsized}
                inspectionByRange={reportData.inspectionByRange}
            />}
            {reportType==='quality_control' && <QualityControlLog data={reportData}/>}
            {reportType==='weekly_summary' && reportData && <WeeklySummaryReport
                totalInspections={reportData.totalInspections}
                preliminaryInspections={reportData.preliminaryInspections}
                criticalJoints={reportData.criticalJoints}
                inspectionPassing={reportData.inspectionPassing}
            />}
            {reportType==='certification_summary' && reportData && <CertificationReport
                overallOutOfConformance={reportData.overallOutOfConformance}
                defectsFound={reportData.defectsFound}
                upsizedPlates={reportData.upsizedPlates}
                placementTolerance={reportData.placementTolerance}
                frequencySummary={reportData.frequencySummary}
                trussSelection={reportData.trussSelection}
                inspectionDetail={reportData.inspectionDetail}
                scoring={reportData.scoring}
            />}
            {reportType==='comment' && <CommentReport data={reportData}/>}
            {reportType==='joint' && reportData && <JointReport
                summary={reportData.summary}
                breakdown={reportData.breakdown}
                jointsByRange={reportData.jointsByRange}
            />}
            {reportType==='plant' && <PlantReport data={reportData} />}
            {reportType==='crew_summary' && reportData && <CrewSummaryReport
                overallQCInspections={reportData.overallQCInspections}
                crewSummary={reportData.crewSummary}
            />}
            {reportType==='log_report' && reportData && metaData && <LogReport
                reportData={reportData}
                locationID={locationID}
                metaData={metaData}
            />}
        </div>
    </Fragment>
}

export default Reports;