import moment from "moment-timezone";

export const LOCATION_EDIT = 'Locations - Edit'
export const WEEKDAY = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export interface FieldData {
    avg_setup_reported?: number
    avg_setup_calculated?: number
    inspection_expected?: number
    inspection_actual?: number
    inspection_expected_per_week?: number
    inspection_actual_per_week?: number
    floor_inspection_expected?: number
    floor_inspection_actual?: number
    floor_inspection_expected_per_week?: number
    floor_inspection_actual_per_week?: number
    roof_inspection_expected?: number
    roof_inspection_actual?: number
    roof_inspection_expected_per_week?: number
    roof_inspection_actual_per_week?: number
    avg_span?: number
    percent_floor_reported?: number
    percent_floor_calculated?: number
    avg_critical_joints_per_inspection?: number
    joint_count?: number
    member_count?: number
    out_of_conformance_total_count?: number
    out_of_conformance_preliminary_percent?: number
    out_of_conformance_detailed_percent?: number
    out_of_conformance_percent_history?: any
    plates_upsized_percent: number
    non_zero_distance_count: number
    avg_misplacement: number
    non_zero_distance_percent: number
    avg_distance_of_non_zero: number
    sd_distance: number
    non_zero_rotation_count: number
    avg_rotation: number
    non_zero_rotation_percent: number
    avg_rotation_of_non_zero: number
    sd_rotation: number
    non_zero_lumber_count: number
    non_zero_plate_count: number
    avg_lumber: number
    avg_plate: number
    avg_lumber_of_non_zero: number
    avg_plate_of_non_zero: number
    sd_lumber: number
    sd_plate: number
    non_zero_lumber_percent: number
    non_zero_plate_percent: number
    member_found_with_tooth_defect: number
}

export const AVERAGE = 'Average'
export const MISPLACEMENT = 'Misplacement (/16)'
export const ROTATION = 'Rotation (deg)'
export const LOCATION_AVERAGE = 'Location Average'
export const CERTIFIED_AVERAGE = 'Certified Average'
export const INSPECTION_PER_SHIFT_PER_WEEK = 3
export const DISABLED_DATE = (current: moment.Moment) => { return (current && current.startOf('day').isAfter(moment().startOf('day')))};
export const LOCATION_ID = 'location_id'
export const QUARTER = 'quarter'
export const DATE = 'date'
export const QC_MANAGER = 'qc_manager'
export const ADMINISTRATOR = 'administrator'
export const TIMESTAMP_YEAR = 'timestamp_year'
export const START_DATE = 'start_date'
export const END_DATE = 'end_date'
export const SCHEMA = 'schema'
export const IS_OOC = 'isOOC'
export const ISEDIT = 'isEdit'
export const TITLE = 'title'

export const LOCATION_ID_VALUE = parseInt(localStorage.getItem(LOCATION_ID) || '')
export const START_DATE_VALUE = localStorage.getItem(START_DATE) || ''
export const END_DATE_VALUE = localStorage.getItem(END_DATE) || ''
export const SCHEMA_VALUE = localStorage.getItem(SCHEMA) || ''
export const IS_OOC_VALUE = Boolean(localStorage.getItem(IS_OOC) || '')

export const WEEKLY_DAILY_HOURS_INITIAL_ROWS = 10
export const addTextButtonStyle = {
    color: '#3498DB',
    borderColor: '#3498DB'
}
export const DASHBOARDSTATUS = {
    Completed: "Completed",
    NotCompleted: "Not_completed"
};

export const ROLE_VALUES = {
    ADMINISTRATOR: 6,
    DISTRICT_MANAGER: 5,
    REGIONAL_MANAGER: 4,
    LOCATION_QC_MANAGER: 3,
    QC_MANAGER: 2,
    INSPECTOR: 1
}