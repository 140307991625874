import React from "react";
import { AuthContext } from "../user/AuthProvider";
import {Col, Row, Select, Space, Typography} from "antd";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";
import {useAtom, useAtomValue} from "jotai";

import {
    companiesAtomVariable,
    companyIdAtomVariable,
    locationIdAtomVariable,
    locationsAtomVariable,
} from '../../view/MainView'
interface DropDownComponentProps{
    title: React.ReactNode
    items:any[]
    filterID?: number
    placeHolder?: string
    value:number | undefined
    onChange?:(value:number|undefined, items:any[])=>void
    disabled?:boolean
}

export interface LocationsFilterBaseProps {
}

export interface LocationsFilterProps extends LocationsFilterBaseProps{
    initialFilters?: { [key: string]: any }
    onChange?:(value:number|undefined, items:any[])=>void
    onChangeCompany?:(value:number|undefined, items:any[])=>void
    disabledValidatorLocation?:(user:any)=>boolean
    disabledValidatorCompany?:(user:any)=>boolean
}

export const DropDownComponent: React.FunctionComponent<DropDownComponentProps> = (props) => {
    const {Text} = Typography;
    const {Option} = Select;

    if (props.disabled !== false){
        return <Space style={{width: '25vw', margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={7}>
                    <Text strong style={{marginRight: 6}}>{props.title}</Text>
                </Col>
                <Col span={7}>
                    <Select
                        key={props.filterID}
                        showSearch
                        disabled
                        style={{width: 400}}
                        placeholder=''
                        optionFilterProp="children"
                        value={props.items?.length > 0 ? props.items?.[0]?.name : undefined}
                        loading={!(props.items?.length > 0)}
                    />
                </Col>
            </Row>
        </Space>
    }
    else{
        return <Space style={{width: '25vw', margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={7}>
                    <Text style={{marginRight: 6}} strong>{props.title}</Text>
                </Col>
                <Col span={7} >
                    <Select
                        key={props.filterID}
                        showSearch
                        value={props.items?.length > 0 ? props.value : undefined}
                        style={{width: 400}}
                        placeholder={props.placeHolder}
                        optionFilterProp="children"
                        onChange={
                            (value: number) => {
                                props.onChange?.(value, props.items)
                            }
                        }
                        loading={!(props.items?.length > 0)}
                    >
                        {props.items?.map(({id, name}) => <Option key={id} value={id} children={name}/>)}
                    </Select>
                </Col>
            </Row>
        </Space>
    }
}

export const LocationsFilter: React.FunctionComponent<LocationsFilterProps> = (props) => {
    const { t } = useTranslation();

    const [companyIdAtom, setCompanyIdAtom] = useAtom(companyIdAtomVariable);
    const [locationIdAtom, setLocationIdAtom] = useAtom(locationIdAtomVariable);
    const companiesAtom = useAtomValue(companiesAtomVariable);
    const locationsAtom = useAtomValue(locationsAtomVariable);
    const handleCompanyChange = async (value: number | undefined, items: any[]) => {
        setLocationIdAtom(undefined);
        setCompanyIdAtom(value);
    };

    const handleLocationChange = (value: number | undefined, items: any[]) => {
        setLocationIdAtom(value)
    };

    return (
        <AuthContext.Consumer>
            {auth => (
                <div>
                    <DropDownComponent
                        title={<Trans i18nKey="company.company_name" defaults="Company" />}
                        items={companiesAtom}
                        placeHolder={`${t('please_enter_company')}`}
                        value={companyIdAtom}
                        onChange={handleCompanyChange}
                        disabled={props.disabledValidatorCompany ? props.disabledValidatorCompany(auth.user) : false}
                    />
                    <DropDownComponent
                        title={<Trans i18nKey="location" defaults="Location" />}
                        items={locationsAtom}
                        filterID={companyIdAtom}
                        placeHolder={`${t('please_enter_location')}`}
                        value={locationIdAtom}
                        onChange={handleLocationChange}
                        disabled={companyIdAtom === undefined || (props.disabledValidatorLocation ? props.disabledValidatorLocation(auth.user) : false)}
                    />
                </div>
            )}
        </AuthContext.Consumer>
    );
};
