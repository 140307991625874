import { Trans as T, TransProps, useTranslation } from "react-i18next";
import { KeyPrefix, Namespace, TFuncKey } from "i18next";
import * as React from "react";
import { DefaultNamespace } from "react-i18next/TransWithoutContext";

export function Trans<
    K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
    N extends Namespace = DefaultNamespace,
    TKPrefix extends KeyPrefix<N> = undefined,
    E = React.HTMLProps<HTMLDivElement>
>(props: TransProps<K, N, TKPrefix, E>) {
    // @ts-ignore
    const { t, i18n } = useTranslation<any, any>(props.ns);

    // @ts-ignore
    return T({ ...props, t, i18n });
}