import moment from "moment/moment";

export const validateEmail = (email:string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone:string) => {
    if (phone){
        const re = /^(?:\+?(1))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?$/;
        const re2 = /^[\d-.\s_x/]+$/;
        return re.exec(String(phone)) || re2.exec(String(phone))
    }
    else{
        return true
    }
}

export const validateZipPostal = (zip_code:string, country_code:string|undefined) => {
    let re;
    if (country_code===undefined){
        return true;
    }
    else if(country_code==='US'){
        re = /^\d{5}(?:[-\s]\d{4})?$/;
    }
    else{
        re = /^[abceghj-nprstvxyABCEGHJ-NPRSTVXY]\d[abceghj-nprstv-zABCEGHJ-NPRSTV-Z][ -]?\d[abceghj-nprstv-zABCEGHJ-NPRSTV-Z]\d$/;
    }
    return re.test(String(zip_code))
}

export const validateVersion = (version:string) => {
    if (version){
        const re = /^[\d]+\.[\d]+\.[\d]+$/;
        return re.exec(String(version));
    }
    else{
        return true
    }
}

export const compare = (a: string, b: string) => {
    const A = a.toUpperCase()
    const B = b.toUpperCase()
    if(A<B) return -1
    if(A>B) return 1
    return 0
}

export const validateAreaCode = (areaCode:string) => {
    const re = /^[\d]+$/;
    return areaCode.length === 3 && re.test(String(areaCode));
}

export const handleScroll = () => {
    document.querySelectorAll('div.ant-table-body').forEach(table => {
        table.scrollTop = 0
    })
}

export const processName = (snakeCaseInput: string) => {
    const splitName = snakeCaseInput.split('_')
    const nameWithoutPoint = splitName?.filter(name => name!=='point')
    let processedName = ''
    for(let i=0; i<nameWithoutPoint.length; i++) {
        const nameWithFirstLetterCapital = nameWithoutPoint[i][0].toUpperCase() + nameWithoutPoint[i].substring(1)
        processedName += nameWithFirstLetterCapital + ' '
    }
    return processedName
}

export const getQuarterDate = (quarterString: string): moment.Moment | null => {
    if (!quarterString) {
        return null;
    }
    const [year, quarter] = quarterString.split('-Q');

    switch (quarter) {
        case '1':
            return moment(`${year}-01-01`);
        case '2':
            return moment(`${year}-04-01`);
        case '3':
            return moment(`${year}-07-01`);
        case '4':
            return moment(`${year}-10-01`);
        default:
            return null;
    }
};