import React, {Fragment} from "react";
import {Button, Col, PageHeader, Row, Select, Space, Tag, Tooltip, Typography} from "antd";
import {Trans, useTranslation} from "react-i18next";
import {TableFilter} from "../filters/TableFilter";
import {configs, fetch, fetch_locations} from "../../api";
import {handleScroll} from "../../utils/util";
import {Page, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {ConfigsContext} from "../configs/ConfigsProvider";
import dayjs from 'dayjs';
import {MapToFilter} from "../filters/mapping";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import CustomDropDown from "../lib/CustomDropDown";
import {useHistory} from "react-router-dom";
import {useSetAtom} from "jotai";
import {
    companyIdAtomVariable,
    locationIdAtomVariable,
    quarterAtomVariable
} from "../../view/MainView";
import {unstable_batchedUpdates} from "react-dom";

export const ReviewApprovals:React.FunctionComponent = ({}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const setCompanyIdAtom = useSetAtom(companyIdAtomVariable);
    const setLocationIdAtom = useSetAtom(locationIdAtomVariable);
    const setQuarterAtom = useSetAtom(quarterAtomVariable);
    const fetchReviewApprovals = async (values: {[key:string]: any}, page?: Page) => {
        const filters = MapToFilter(values)
        const payload = {
            tableName: 'review_request_admin',
            orderBy: ['id desc'],
            filter:[...filters],
            page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
        }
        return await fetch(payload) || {}
    }

    const handleProceed = (company_id: number, location_id: number, quarter: string) => {
        unstable_batchedUpdates(() => {
            setCompanyIdAtom(company_id);
            setLocationIdAtom(location_id);
            setQuarterAtom(quarter);
        });
        history.push('/view/review-dashboard');
    };

    return (
        <Fragment>
            <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                <Row>
                    <Col span={12}>
                        <PageHeader title={<Trans i18nKey={`review_approvals_page.review_request_title`} defaults={`Review Approvals`}/>} />
                    </Col>
                </Row>
            </div>
            <TableV2
                schema={'review_approvals'}
                maxColumnWidth={400}
                header={
                    <div style={{ paddingRight: 7, width: '100%'}}>
                        <TableFilter
                            schema={'review_approvals_filter'}
                            fetch={fetchReviewApprovals}
                            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                if(element.name === 'location_id'){
                                    return <CustomDropDown
                                        key={element.name}
                                        schema={schema}
                                        name={element.name}
                                        filterValue={form.getFieldValue('company_id')}
                                        fetch={(request:{tableName:string, filter:{[key:string] : any}}) => {
                                            return fetch_locations(form.getFieldValue('company_id'))
                                        }}
                                        enum_map={['id', 'name']}
                                    />
                                }
                                return undefined
                            }}
                        />
                    </div>
                }
                fetchConfig={async (schema) => {
                    const res = await configs(schema);
                    res.data.rows = res.data.rows.map((row: any) => {
                        if (row.name) {
                            row.title = <Trans i18nKey={`review_approvals.${row.name}`} defaults={row.title}/>;
                        }
                        return row;
                    });
                    return res
                }}
                fetchData={async (data_request) => {
                    handleScroll()
                    const filters = MapToFilter(data_request.options?.values || {})
                    const page = {
                        size: data_request?.page?.size || 100,
                        current: data_request?.page?.current || 1,
                        total: data_request?.page?.total || 0
                    };
                    const response = await fetchReviewApprovals(filters, page)
                    return response as Response
                }}
                customRenderer={{
                    status: (value, row, index, column) => {
                        const nameCard = (name: string, color: string) =>
                            <Tag color={color} style={{margin: '4px', padding: '5px 15px 5px 15px'}}>{name}</Tag>
                        return <Space>
                            {(value == 'PENDING') ? <Button onClick={() => handleProceed(row.company_id, row.location_id, row.quarter)}
                               style={{ color: 'blue', border: '1px solid blue', margin: '0px', fontSize: '12px'}}>
                                    <Trans i18nKey={'review_approvals_page.proceed'} default={'Proceed'} />
                                </Button>
                                : nameCard(t('review_approvals_page.reviewed'), 'green')}
                        </Space>
                    },
                    created_at: (value, row, index, column) => {
                        return <ConfigsContext.Consumer>{configs =>
                            <Typography.Text>{dayjs(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                    },
                    reviewed_at: (value, row, index, column) => {
                        return (
                            <ConfigsContext.Consumer>
                                {configs => (
                                    value ? (
                                        <Typography.Text>{dayjs(value).format(configs.dateTimeFormat)}</Typography.Text>
                                    ) : (
                                        <Typography.Text><Trans i18nKey={`review_requests_page.pending_review_status`}
                                                                defaults={`Pending Review`}/></Typography.Text>
                                    )
                                )}
                            </ConfigsContext.Consumer>
                        );
                    }
                }}
                scroll={{y: 'calc(100vh - 441px)', x: 'max-content'}}
            />
        </Fragment>
    )
}